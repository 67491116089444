import { Navigate } from "react-router-dom";
import { getToken } from "../utilites/getToken";

const PublicRoute = ({ element }) => {
  const token = getToken();

  return token ? <Navigate to="/" replace /> : element;
};

export default PublicRoute;
