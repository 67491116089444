import React, { useState } from 'react';
import "./modelStyle.css"

const Modal = ({ inputValue, onInputChange, onSubmit, onClose, portalLoading, emailLoading }) => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!inputValue.trim()) {
            setErrorMessage('File name cannot be empty.');
            return;
        }
        setErrorMessage('');
        onSubmit();
    };

    return (
        <div className="modal-file">
            <form className="modal-content-file" onSubmit={handleSubmit}>
                <h2>Enter File Name</h2>
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                        onInputChange(e);
                        setErrorMessage('');
                    }}
                    placeholder="Enter file name"
                />
                {errorMessage && <div className="error-message" style={{ color: "#c1121f" }}>{errorMessage}</div>}
                <div className='modal-btns'>
                    <button onClick={onClose} className='close-btn' type="button">Close</button>
                    <button className='save-btn' type='submit'>
                        {portalLoading ? "Uploading....." : emailLoading ? "Sending file on email..." : "Submit File"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Modal;
