import React, { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SignatureCanvas from "react-signature-canvas";
import FolderModel from "../../model/FolderModel";
import Compressor from "compressorjs";
import DynamicWidthInput from "../../component/GeneratePDFPages/DynamicWidthInput";
import { sendEmail } from "../../utilites/api";
import { getUserEmail } from "../../utilites/getToken";

const Page1 = () => {
  const [loading, setLoading] = useState(false);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const sigCanvas = useRef(null);
  const signaturePadRef = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const userEmail = getUserEmail();

  const openModal = () => {
    if (imageURL === null) {
      alert("Please add signature first.");
      return;
    }
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setShowSignaturePad(false);
  };

  const clear = () => sigCanvas.current.clear();

  const compressImage = (canvas) => {
    return new Promise((resolve, reject) => {
      // Higher resolution for better quality
      const targetWidth = 5600; // Increase resolution
      const targetHeight = (canvas.height / canvas.width) * targetWidth;

      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = targetWidth;
      offscreenCanvas.height = targetHeight;
      const ctx = offscreenCanvas.getContext("2d");
      ctx.drawImage(canvas, 0, 0, targetWidth, targetHeight);

      offscreenCanvas.toBlob(
        (blob) => {
          new Compressor(blob, {
            quality: 0.7, // Increase quality for larger file size
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        },
        "image/jpeg", // JPEG is good for better compression and quality balance
        0.7 // Increase quality factor for a larger file
      );
    });
  };

  const generatePDFBytes = async () => {
    const containers = document.querySelectorAll("#pdf-content .container");
    const pdf = new jsPDF("p", "mm", "a4");

    const addImageToPDF = async (canvas) => {
      const imgBlob = await new Promise((resolve) => {
        canvas.toBlob(resolve, "image/jpeg"); // Maximum quality
      });

      const imgData = URL.createObjectURL(imgBlob);
      const img = new Image();
      img.src = imgData;

      await new Promise((resolve) => {
        img.onload = () => {
          const pdfWidth = 210; // Width of the A4 page in mm
          const pdfHeight = 275; // Height of the A4 page in mm

          const imgAspectRatio = img.width / img.height;
          const pdfAspectRatio = pdfWidth / pdfHeight;

          let scaledImgWidth = pdfWidth;
          let scaledImgHeight = pdfHeight;
          let xOffset = 0;
          let yOffset = 0;

          if (imgAspectRatio < pdfAspectRatio) {
            // If the image is taller relative to its width than the page
            scaledImgHeight = pdfHeight;
            scaledImgWidth = pdfHeight * imgAspectRatio;
            xOffset = (pdfWidth - scaledImgWidth) / 2; // Center horizontally
          } else {
            // If the image is wider relative to its height than the page
            scaledImgWidth = pdfWidth;
            scaledImgHeight = pdfWidth / imgAspectRatio;
          }

          // Add the image to the PDF, aligning to fill the height of the page
          pdf.addImage(
            img.src,
            "JPEG", // Use JPEG format
            xOffset,
            yOffset,
            scaledImgWidth,
            scaledImgHeight
          );
          resolve();
        };
      });
    };

    for (let i = 0; i < containers.length; i++) {
      const container = containers[i];
      await html2canvas(container, { scale: 4 }).then(async (canvas) => {
        if (i > 0) {
          pdf.addPage();
        }
        await addImageToPDF(canvas);
      });
    }

    // Return PDF as an ArrayBuffer
    return pdf.output("arraybuffer");
  };

  const downloadPDF = async () => {
    if (imageURL === null) {
      alert("Please add signature first.");
      return;
    }

    setLoading(true);
    try {
      const pdfBytes = await generatePDFBytes();

      // Directly use the pdfBytes in the `downloadPDF` function
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "document.pdf";
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendFileOnEmail = async () => {
    if (!userEmail || userEmail.length === 0) {
      alert("Please enter your email address first.");
      return;
    }

    if (!imageURL) {
      alert("Please add signature first.");
      return;
    }

    setEmailLoading(true);

    try {
      const pdfBytes = await generatePDFBytes();
      const pdfFile = new File([pdfBytes], "document.pdf", {
        type: "application/pdf",
      });

      const formData = new FormData();
      formData.append("pdf", pdfFile);

      userEmail.forEach((email, index) => {
        formData.append(`emails[${index}]`, email);
      });

      await sendEmail(formData);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    } finally {
      setEmailLoading(false);
    }
  };

  return (
    <div>
      <div id="pdf-content">
        <div className="container">
          <div className="header">
            <img src="/Images/logo.png" alt="Company Logo" />
          </div>
          <div className="title" style={{ marginBottom: 0, marginTop: "70px" }}>
            <h1 style={{ color: "#000" }}>
              HIPAA BUSINESS ASSOCIATE AGREEMENT
            </h1>
          </div>
          <div
            style={{
              background: "#fff",
              borderTop: "2px solid #000",
              borderBottom: "2px solid #000",
              padding: "1px",
              marginBottom: "",
            }}
          ></div>
          <p style={{ textAlign: "start" }}>
            This HIPAA Business Associate Agreement (“Agreement”) is made
            effective as of <DynamicWidthInput />, by and between{" "}
            <DynamicWidthInput /> (“Covered Entity”), of <DynamicWidthInput />,
            and CYBERDENTAL CORP. (“Business Associate”), of 480 West 84th
            Street, Suite B106, Hialeah, FL 33014 (collectively, the “Parties”).
          </p>
          <p>
            WHEREAS, Business Associate, in connection with its services, may
            maintain, transmit, create or receive data for or from Covered
            Entity that constitutes Protected Health Information (“PHI”);
          </p>
          <p>
            WHEREAS, Covered Entity is or may be subject to the requirements of
            the Federal Health Insurance Portability and Accountability Act of
            1996 (“HIPAA”), the Health Information Technology for Economic and
            Clinical Health Act (“HITECH”), and related regulations;
          </p>
          <p>
            WHEREAS, with respect to the foregoing, Business Associate is or may
            be subject to the requirements of HIPAA, HITECH and related
            regulations;
          </p>
          <p>
            NOW, THEREFORE, in consideration of the mutual promises and
            covenants contained herein, the Parties hereby agree as follows:
          </p>
          <h2>1. Definitions.</h2>
          <h3>a. General.</h3>
          <p>
            The following terms used in this Agreement shall have the same
            meaning as those terms in the HIPAA Rules: Breach, Data Aggregation,
            Designated Record Set, Disclosure, Electronic Protected Health
            Information, Health Care Operations, Individual, Minimum Necessary,
            Notice of Privacy Practices, Protected Health Information, Required
            by Law, Secretary, Security Incident, Subcontractor, Unsecured
            Protected Health Information, and Use.
          </p>
          <h3>b. Specific.</h3>
          <ul>
            <li>
              <strong>i. Business Associate.</strong> “Business Associate” shall
              generally have the same meaning as the term “business associate”
              at 45 CFR 160.103, and in reference to the party to this
              Agreement, shall mean CYBERDENTAL CORP.
            </li>
            <li>
              <strong>ii. Covered Entity.</strong> “Covered Entity” shall
              generally have the same meaning as the term “covered entity” at 45
              CFR 160.103, and in reference to the party to this Agreement,
              shall mean <DynamicWidthInput />.
            </li>
            <li>
              <strong>iii. Electronic Health Record.</strong> “Electronic Health
              Record” shall have the same meaning as the term “electronic health
              record” in the HITECH Act, Section 13400.
            </li>
            <li>
              <strong>iv. HIPAA.</strong> “HIPAA” collectively refers to the
              HIPAA Statute, including the Privacy, Security, Breach
              Notification, and Enforcement Rules at 45 CFR Part 160 and Part
              164, the HITECH Act, and any associated Regulations, as such may
              be amended from time to time.
            </li>
          </ul>
        </div>

        <div className="container">
          <h2>2. Obligations and Activities of Business Associate.</h2>
          <ul>
            <li>
              a. Business Associate agrees not to use or disclose PHI other than
              as permitted or required by the Agreement or as required by law.
            </li>
            <li>
              b. Business Associate agrees to use appropriate safeguards, and
              comply with Subpart C of 45 CFR Part 164 with respect to
              Electronic PHI, to prevent use or disclosure of PHI other than as
              provided for by the Agreement.
            </li>
            <li>
              c. Business Associate agrees to report to Covered Entity any use
              or disclosure of PHI not provided for by the Agreement of which it
              becomes aware, including breaches of unsecured PHI as required at
              45 CFR 164.410, and any security incident of which it becomes
              aware.
            </li>
            <li>
              d. In accordance with 45 CFR 164.502(e)(1) and 164.308(b)(2), if
              applicable, Business Associate agrees to ensure that any
              subcontractors that create, receive, maintain, or transmit PHI on
              behalf of the Business Associate agree to the same restrictions,
              conditions, and requirements that apply to the Business Associate
              with respect to such information.
            </li>
            <li>
              e. In accordance with 45 CFR 164.524, Business Associate agrees to
              make available PHI in a designated record set to the Covered
              Entity within twenty (20) days of a request by Covered Entity for
              access to PHI about an individual. In the event that any
              individual requests access to PHI directly from Business
              Associate, Business Associate shall forward such request to
              Covered Entity within twenty (20) days of receiving such request.
            </li>
            <li>
              f. In accordance with 45 CFR 164.526, Business Associate agrees to
              make any amendment(s) to PHI in a designated record set within
              twenty (20) days of a request by Covered Entity. Business
              Associate shall provide such information to Covered Entity for
              amendment and incorporate any amendments in the PHI as required by
              45 CFR 164.526. In the event a request for an amendment is
              delivered directly to Business Associate, Business Associate shall
              forward such request to Covered Entity within twenty (20) days of
              receiving such request.
            </li>
            <li>
              g. Except for disclosures of PHI by Business Associate that are
              excluded from the accounting obligation set forth in 45 CFR
              164.528 or regulations issued pursuant to HITECH, Business
              Associate shall record for each disclosure the information
              required to be recorded by Covered Entities pursuant to 45 CFR
              164.528. Within twenty (20) days of notice by Covered Entity to
              Business Associate that it has received a request for an account
              of disclosures of PHI, Business Associate shall make available to
              Covered Entity, or if requested by Covered Entity, to the
              individual, the information required to be maintained pursuant to
              this Agreement. In the event the request for an accounting is
              delivered directly to Business Associate, Business Associate shall
              forward such request to Covered Entity within twenty (20) days of
              receiving such request.
            </li>
            <li>
              h. To the extent the Business Associate is to carry out one or
              more of Covered Entity’s obligations) under Subpart E of 45 CFR
              Part 164, Business Associate agrees to comply with the
              requirements of Subpart E that apply to the Covered Entity in the
              performance of such obligation(s).
            </li>
            <li>
              i. Business Associate agrees to make its internal practices,
              books, and records relating to the use and disclosure of PHI
              available to the Secretary for purposes of determining compliance
              with HIPAA.
            </li>
          </ul>
        </div>

        <div className="container">
          <div className="content">
            <h2>3. Permitted Uses and Disclosures by Business Associate</h2>
            <ul>
              <li>
                a. Business Associate may use or disclose PHI for the following
                purposes: As necessary to perform the services as agreed to
                between the Parties, notwithstanding the restrictions on such
                uses and disclosures as set forth in HIPAA and this Agreement.
              </li>
              <li>
                b. Business Associate may only de-identify PHI if permitted by
                Covered Entity and in any event may only de-identify PHI in
                accordance with 45 CFR 164.514(a)-(c).
              </li>
              <li>
                c. Business Associate may use or disclose PHI as required by law
                or where Business Associate obtains reasonable assurances from
                the person to whom the information is disclosed that the
                information will remain confidential and used or further
                disclosed only as required by law or for the purposes for which
                it was disclosed to the person, and the person notifies Business
                Associate of any instances of which it is aware in which the
                confidentiality of the information has been breached.
              </li>
              <li>
                d. Business Associate may not use or disclose PHI in a manner
                that would violate Subpart E of 45 CFR Part 164 if done by
                Covered Entity except for the specific uses and disclosures set
                forth herein.
              </li>
            </ul>
            <h2>4. Permissible Requests by Covered Entity</h2>
            <ul>
              <li>
                a. Except as otherwise permitted by this Agreement, Covered
                Entity shall not request Business Associate to use or disclose
                PHI in any manner that would not be permissible under Subpart E
                of 45 CFR Part 164 if done by Covered Entity.
              </li>
            </ul>
            <h2>5. Term and Termination</h2>
            <ul>
              <li>
                a. Term. The Term of this Agreement shall be effective as of{" "}
                <DynamicWidthInput />, and shall terminate on the date the
                business relationship, or any services agreements, between the
                Parties end or are terminated or on the date Covered Entity
                terminates for cause as authorized in paragraph (b) of this
                Section.
              </li>
              <li>
                b. Termination for Cause. Business Associate authorizes
                termination of this Agreement by Covered Entity, if Covered
                Entity determines Business Associate has violated a material
                term of the Agreement and Business Associate has not cured the
                breach or ended the violation within fifteen (15) days written
                notice. If it is determined by Covered Entity that cure is not
                possible, Covered Entity may immediately terminate this
                Agreement. The termination of this Agreement shall automatically
                terminate the business relationship and any services agreements
                between the Parties.
              </li>
              <li>
                c. Obligations of Business Associate Upon Termination. Upon
                termination of this Agreement, Business Associate shall either
                return or destroy all PHI that Business Associate still
                maintains in any form. Business Associate shall not retain any
                copies of such PHI. In the event Business Associate determines
                that returning or destroying the PHI is infeasible, the terms of
                this Agreement shall survive termination with respect to such
                PHI and limit further uses and disclosures of such PHI for so
                long as Business Associate maintains such PHI. In addition,
                Business Associate shall continue to use appropriate safeguards
                and comply with Subpart C of 45 CFR Part 164 with respect to
                electronic PHI to prevent use or disclosure of the PHI for as
                long as business associate retains the PHI.
              </li>
              <li>
                d. Survival. The obligations of Business Associate under this
                Section shall survive the termination of this Agreement.
              </li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="content">
            <h2>6. General Provisions.</h2>
            <ul>
              <li>
                a. This agreement sets forth the entire understanding of the
                Parties. Any amendments must be in writing and signed by both
                Parties. This Agreement shall be construed under the laws of the
                State of Florida, without regard to conflict of law provisions.
                Any ambiguity in the terms of this Agreement shall be resolved
                to permit compliance with HIPAA. Any references in this
                Agreement to a section in HIPAA means the section as in effect
                or as may be amended. This Agreement may be modified or amended
                from time to time as is necessary for compliance with the
                requirements of HIPAA and other applicable law. Amendments must
                be made in writing and signed by the Parties. The failure of
                either Party to enforce any provision of this Agreement shall
                not be construed as a waiver or limitation of that Party's right
                to subsequently enforce and compel strict compliance with every
                provision of this Agreement. The terms of this Agreement are
                hereby incorporated into any service or business agreement that
                may be entered into between the Parties with the intent to form
                a business relationship. In the event of a conflict of terms
                between this Agreement and any such service or business
                agreement the terms of this Agreement shall prevail.
              </li>
              <li>
                IN WITNESS WHEREOF, I have hereunto set my hand to this HIPAA
                Business Associate Agreement as of the date set forth above
              </li>
            </ul>
          </div>
          <div style={{ paddingBottom: "50px" }}>
            <table style={{ width: "100%" }} className="table-main">
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>
                    <div>
                      <h2 style={{ fontWeight: "600", fontSize: "16px" }}>
                        Covered Entity
                      </h2>
                      <div
                        style={{ paddingRight: "60px", marginTop: "36px" }}
                        onClick={() => setShowSignaturePad(true)}
                      >
                        {imageURL ? (
                          <img
                            src={imageURL}
                            alt="my signature"
                            style={{
                              display: "block",
                              borderBottom: "1px solid #000",
                              width: "250px",
                              height: "70px",
                            }}
                            className="signature"
                          />
                        ) : (
                          <button>Add Signature</button>
                        )}
                      </div>
                      <div style={{ paddingTop: "30px" }}>
                        <span>By: </span>
                        <DynamicWidthInput />
                      </div>
                      <div style={{ paddingTop: "6px" }}>
                        <span>Title: </span>
                        <DynamicWidthInput />
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "50%", paddingLeft: "40px" }}>
                    <div>
                      <h2 style={{ fontWeight: "600", fontSize: "16px" }}>
                        Business Associate
                      </h2>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid #000", height: "53px" }}
                    >
                      <img
                        src="/Images/sign.png"
                        alt="Sign"
                        style={{ width: "240px" }}
                      />
                    </div>
                    <div style={{ paddingTop: "30px" }}>
                      <span>By: </span>
                      <span> Mr. Dimitri Lopez </span>
                    </div>
                    <div style={{ paddingTop: "6px" }}>
                      <span>Title:</span>
                      <span> CEO</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="table-mobile">
              <div>
                <h2 style={{ fontWeight: "600", fontSize: "16px" }}>
                  Covered Entity
                </h2>
                <div
                  style={{ paddingRight: "60px", marginTop: "36px" }}
                  onClick={() => setShowSignaturePad(true)}
                >
                  {imageURL ? (
                    <img
                      src={imageURL}
                      alt="my signature"
                      style={{
                        display: "block",
                        borderBottom: "1px solid #000",
                        width: "250px",
                        height: "70px",
                      }}
                    />
                  ) : (
                    <button>Add Signature</button>
                  )}
                </div>
                <div style={{ paddingTop: "30px" }}>
                  <span>By: </span>
                  <DynamicWidthInput />
                </div>
                <div style={{ paddingTop: "6px" }}>
                  <span>Title: </span>
                  <DynamicWidthInput />
                </div>
              </div>
              <div>
                <div>
                  <h2 style={{ fontWeight: "600", fontSize: "16px" }}>
                    Business Associate
                  </h2>
                </div>
                <div style={{ borderBottom: "1px solid #000", height: "53px" }}>
                  <img
                    src="/Images/sign.png"
                    alt="Sign"
                    style={{ width: "240px" }}
                  />
                </div>
                <div style={{ paddingTop: "30px" }}>
                  <span>By: </span>
                  <span> Mr. Dimitri Lopez </span>
                </div>
                <div style={{ paddingTop: "6px" }}>
                  <span>Title:</span>
                  <span> CEO</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "100px", paddingBottom: "40px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <img src="/Images/phone.png" alt="" />
              <span>(954) 639 7049</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginTop: "14px",
              }}
            >
              <img src="/Images/link.png" alt="" />
              <span>cyberdental.co</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginTop: "14px",
              }}
            >
              <img src="/Images/location.png" alt="" />
              <span
                style={{
                  maxWidth: "242px",
                  fontSize: "14px",
                  color: "#212121",
                  lineHeight: "20px",
                }}
              >
                480 West 84th Street, Suite B106, Hialeah, FL 33014
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-main">
        <button className="email-btn" onClick={sendFileOnEmail}>
          {emailLoading ? "Sending file..." : "Send File On Email"}
        </button>
        <button
          className="download-btn"
          style={{ cursor: "pointer" }}
          disabled={loading}
          onClick={downloadPDF}
        >
          {loading ? "Downloading..." : "Download PDF"}
        </button>
      </div>

      {/* <div className="btn-main">
        <button
          className="download-btn"
          style={{ cursor: "pointer" }}
          onClick={openModal}
        >
          Save On Portal
        </button>
      </div> */}

      {showSignaturePad && (
        <div className="signature_pad_overlay">
          <div className="signature_pad" ref={signaturePadRef}>
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                width: 300,
                height: 200,
                className: "signature_canvas",
              }}
            />
            <div className="signature_pad_controls">
              <button onClick={save}>Save</button>
              <button onClick={clear}>Clear</button>
              <button onClick={() => setShowSignaturePad(false)}>Close</button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <FolderModel
          isOpen={isModalOpen}
          onClose={closeModal}
          generatePDFBytes={generatePDFBytes}
        />
      )}
    </div>
  );
};

export default Page1;
