import React, { useEffect, useState } from "react";
import "./modelStyle.css";
import { IoCloseSharp } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa6";
import { getFolderData, sendEmail, uploadPDdfileToPortal } from "../utilites/api";
import Modal from "./FileNameModel";
import { getToken, getUserEmail } from "../utilites/getToken";

function FolderModel({ isOpen, onClose, generatePDFBytes }) {
    const [folderData, setFolderData] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderHistory, setFolderHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [portalLoading, setPortalLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false)
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const token = getToken()

    const retrieveFolderData = async () => {
        setLoading(true);
        if (token) {
            try {
                const response = await getFolderData(token);
                if (response.response && response.response.status === 401) {
                    onClose()
                } else {
                    setFolderData(response);
                }
            } catch (error) {
                onClose()
                console.log("error", error);
            } finally {
                setLoading(false);
                setHasFetchedData(true);
            }
        }
    };

    useEffect(() => {
        if (isOpen && !hasFetchedData) {
            retrieveFolderData();
        }
    }, [isOpen, hasFetchedData]);

    const handleFolderClick = (folder) => {
        setFolderHistory([...folderHistory, currentFolder]);
        setCurrentFolder(folder);
    };

    const createPdfFromImages = async () => {
        const pdfBytes = await generatePDFBytes(true);
        return new File([pdfBytes], "images.pdf", { type: "application/pdf" });
    };

    const getCurrentFolderPath = () => {
        const folderNames = folderHistory
            .map((folder) => folder?.folderName)
            .slice(1);
        if (currentFolder) {
            folderNames.push(currentFolder.folderName);
        }
        return folderNames.join("/");
    };

    const handleSubmit = async () => {
        setIsModalOpen(true);
    };

    const handleGoBack = () => {
        if (folderHistory.length > 0) {
            const previousFolder = folderHistory[folderHistory.length - 1];
            setFolderHistory(folderHistory.slice(0, -1));
            setCurrentFolder(previousFolder);
        } else {
            setCurrentFolder(null);
        }
    };

    const sendFileOnEmail = async (pdfFile, fileName) => {
        setEmailLoading(true)
        try {
            const email = getUserEmail()
            const formData = new FormData();
            formData.append("pdf", pdfFile);
            formData.append("fileName", fileName);
            formData.append("emails", email[0])

            await sendEmail(formData);
        } catch (error) {
            console.error("Error uploading PDF:", error);
        } finally {
            setEmailLoading(false)
            setIsModalOpen(false);
            onClose();
        }
    };

    const handleModalSubmit = async () => {
        setPortalLoading(true);
        const folderPath = getCurrentFolderPath();
        try {
            const pdfFile = await createPdfFromImages();
            const fileName = inputValue ? `${inputValue}.pdf` : "document.pdf"
            const formData = new FormData();
            formData.append("file", pdfFile);
            formData.append("folderName", folderPath);
            formData.append("customFileName", inputValue)

            const response = await uploadPDdfileToPortal(formData, token);
            if (response.Files) {
                sendFileOnEmail(pdfFile, fileName)
            }
        } catch (error) {
            console.error("Error uploading PDF:", error);
        } finally {
            setLoading(false);
            setPortalLoading(false);
        }
    };

    const renderFolderContent = (folder) => {
        return (
            <div className="sub-folder modal-main">
                <h3>{folder.folderName}</h3>
                <div className="outer-box">
                    {/* <div className="top-folder"> */}
                    {folder.subfolders.length > 0 && (
                        <div className="box">
                            <h4>Subfolders:</h4>
                            <div className="top-folder">
                                {folder.subfolders.map((subfolder, index) => (
                                    <div
                                        key={index}
                                        className="folder-main"
                                        onClick={() => handleFolderClick(subfolder)}
                                    >
                                        <h4>{subfolder.folderName}</h4>
                                        <div className="folder-detail">
                                            <p>
                                                Subfiles: <span>{subfolder.subfiles.length}</span>
                                            </p>
                                            <p>
                                                Subfolders: <span>{subfolder.subfolders.length}</span>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {/* </div> */}
                    {folder.subfiles.length > 0 && (
                        <div className="box">
                            <h4>Files:</h4>
                            {folder.subfiles.map((file, index) => (
                                <div key={index} className="sub-box">
                                    <h4>{file.fileName}</h4>
                                    <p>Size: {file.size}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderTopLevelFolders = (folders) => {
        return (
            <div className="modal-main">
                <h3>Select Folder To Save File</h3>
                <div className="top-folder">
                    {folders.folders?.length > 0 &&
                        folders.folders.map((folder, index) => (
                            <div
                                key={index}
                                onClick={() => handleFolderClick(folder)}
                                className="folder-main"
                            >
                                <h4>{folder.folderName}</h4>
                                <div className="folder-detail">
                                    <p>
                                        Subfiles: <span>{folder.subfiles.length}</span>
                                    </p>
                                    <p>
                                        Subfolders: <span>{folder.subfolders.length}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="box">
                    <h3>Files:</h3>
                    {folders.files?.length > 0 &&
                        folders.files.map((file, index) => (
                            <div key={index} className="sub-box">
                                <h4>{file.fileName}</h4>
                                <p>Size: {file.size}</p>
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    return (
        <div className={`modal-overlay ${isOpen ? "open" : ""}`}>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <IoCloseSharp />
                </button>
                {loading ? (
                    <div className="loader-box">
                        <div className="loader-02"></div>
                    </div>
                ) : currentFolder ? (
                    <div className="folder">
                        <button className="back-button" onClick={handleGoBack}>
                            <FaArrowLeft />
                        </button>
                        {renderFolderContent(currentFolder)}
                    </div>
                ) : (
                    renderTopLevelFolders(folderData)
                )}
                {!loading && (
                    <div className="submit-btn">
                        <button
                            className="save-btn"
                            disabled={portalLoading}
                            style={{ cursor: "pointer" }}
                            onClick={handleSubmit}
                        >
                            Submit File
                            {/* {portalLoading ? "Uploading....." : emailLoading ? "Sending file on email..." : "Submit File"} */}
                        </button>
                    </div>
                )}
            </div>
            {isModalOpen && (
                <Modal
                    inputValue={inputValue}
                    onInputChange={(e) => setInputValue(e.target.value)}
                    onSubmit={handleModalSubmit}
                    onClose={() => setIsModalOpen(false)}
                    portalLoading={portalLoading}
                    emailLoading={emailLoading}
                />
            )}
        </div>
    );
}

export default FolderModel;
