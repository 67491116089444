export const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('userToken');
    return token
}

export const getUserEmail = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.getAll('email');
    return email
}