import React, { useState, useRef, useCallback, useEffect } from "react";
import "./style/main.scss";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import { CgMergeVertical, CgMergeHorizontal } from "react-icons/cg";
import {
  IoMdUndo,
  IoMdRedo,
  IoIosImage,
  IoMdCloseCircle,
} from "react-icons/io";
import SignatureCanvas from "react-signature-canvas";
import { SketchPicker } from "react-color";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { toPng } from "html-to-image";
import Tesseract from "tesseract.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import FolderModel from "../model/FolderModel";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const Main = () => {
  const filterElement = [
    { name: "brightness", maxValue: 200 },
    { name: "grayscale", maxValue: 200 },
    { name: "sepia", maxValue: 200 },
    { name: "saturate", maxValue: 200 },
    { name: "contrast", maxValue: 200 },
    { name: "hueRotate" },
  ];

  const [property, setProperty] = useState({
    name: "brightness",
    maxValue: 200,
  });
  const [details, setDetails] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [isCropMode, setIsCropMode] = useState(false);
  const [images, setImages] = useState([]);
  const [cropper, setCropper] = useState(null);
  const signaturePadRef = useRef(null);
  const [signatureId, setSignatureId] = useState(null);
  const [isHoveringResize, setIsHoveringResize] = useState(false);
  const [hoveredSignatureId, setHoveredSignatureId] = useState(null);
  const [loadingState, setLoadingState] = useState({
    pdfLoading: false,
  });
  const [loadingScan, setloadingScan] = useState(false);
  const [detectedObject, setdetectedObject] = useState({
    x0: 0,
    y0: 0,
    x1: 0,
    y1: 0,
  });
  const canvasRef = useRef(null);
  const tempCanvasRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [state, setState] = useState({
    brightness: 100,
    grayscale: 0,
    sepia: 0,
    saturate: 100,
    contrast: 100,
    hueRotate: 0,
    rotate: 0,
    vertical: 1,
    horizontal: 1,
  });
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const sigCanvas = useRef(null);
  const fileInputRef = useRef(null);
  const signatureRef = useRef(null);
  const divRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Undo/Redo History Management
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const pushStateToUndoStack = (imageState) => {
    setUndoStack((prevStacks) => ({
      ...prevStacks,
      [selectedIndex]: [...(prevStacks[selectedIndex] || []), imageState],
    }));
  };

  const popStateFromUndoStack = () => {
    setUndoStack((prevStacks) => {
      const newStack = [...(prevStacks[selectedIndex] || [])];
      newStack.pop();
      return {
        ...prevStacks,
        [selectedIndex]: newStack,
      };
    });
  };

  const pushStateToRedoStack = (imageState) => {
    setRedoStack((prevStacks) => ({
      ...prevStacks,
      [selectedIndex]: [...(prevStacks[selectedIndex] || []), imageState],
    }));
  };

  const popStateFromRedoStack = () => {
    setRedoStack((prevStacks) => {
      const newStack = [...(prevStacks[selectedIndex] || [])];
      newStack.pop();
      return {
        ...prevStacks,
        [selectedIndex]: newStack,
      };
    });
  };

  const applyState = useCallback((index, newState) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = { ...updatedImages[index], ...newState };
      return updatedImages;
    });
  }, []);

  const undo = () => {
    if (
      undoStack.length === 0 ||
      selectedIndex === null ||
      undoStack[selectedIndex].length === 0 ||
      !(selectedIndex in undoStack)
    )
      return;

    const previousState =
      undoStack[selectedIndex][undoStack[selectedIndex].length - 1];
    pushStateToRedoStack(images[selectedIndex]);
    popStateFromUndoStack();
    applyState(selectedIndex, previousState);
  };

  const redo = () => {
    if (
      redoStack.length === 0 ||
      selectedIndex === null ||
      redoStack[selectedIndex].length === 0 ||
      !(selectedIndex in redoStack)
    )
      return;

    const nextState =
      redoStack[selectedIndex][redoStack[selectedIndex].length - 1];
    pushStateToUndoStack(images[selectedIndex]);
    popStateFromRedoStack();
    applyState(selectedIndex, nextState);
  };

  const inputHandle = (e) => {
    if (selectedIndex !== null) {
      const newState = { [e.target.name]: e.target.value };
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, newState);
    }
  };

  const rotateLeft = () => {
    if (selectedIndex !== null) {
      const newState = { rotate: state.rotate - 90 };
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, newState);
    }
  };

  const rotateRight = () => {
    if (selectedIndex !== null) {
      const newState = { rotate: state.rotate + 90 };
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, newState);
    }
  };

  const flipVertical = () => {
    if (selectedIndex !== null) {
      const newState = { vertical: state.vertical === 1 ? -1 : 1 };
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, newState);
    }
  };

  const flipHorizontal = () => {
    if (selectedIndex !== null) {
      const newState = { horizontal: state.horizontal === 1 ? -1 : 1 };
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, newState);
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const originalImage = new Image();
        originalImage.src = reader.result;

        originalImage.onload = () => {
          // Store original image dimensions
          const originalWidth = originalImage.width;
          const originalHeight = originalImage.height;

          const existingSignatures =
            images.length > 0 ? images[0].signData.signatures : [];

          const uniqueSignatures = existingSignatures.filter(
            (signature) =>
              !images.some((image) =>
                image.signData.signatures.some(
                  (existingSignature) => existingSignature.id === signature.id
                )
              )
          );

          // Add image data to state
          setImages((prevImages) => [
            ...prevImages,
            {
              originalImage: reader.result, // Store original image data
              image: reader.result, // Displayed and modified image
              originalWidth: originalWidth, // Store original width
              originalHeight: originalHeight, // Store original height
              brightness: 100,
              grayscale: 0,
              sepia: 0,
              saturate: 100,
              contrast: 100,
              hueRotate: 0,
              rotate: 0,
              vertical: 1,
              horizontal: 1,
              // signData: {
              //   isPermentSave: false,
              //   signatures: [],
              // },
              signData: {
                isPermentSave: false,
                signatures: uniqueSignatures.map((signature) => ({
                  ...signature,
                  id: uuidv4(),
                })),
              },
              scanData: {
                scanCropReady: false,
              },
            },
          ]);
          if (selectedIndex === null) {
            setSelectedIndex(0);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (selectedIndex !== null) {
      setState(images[selectedIndex]);
    }
  }, [selectedIndex, images]);

  const handleCrop = () => {
    if (!crop.width || !crop.height || selectedIndex === null) {
      console.error("Invalid crop dimensions or no image selected.");
      return;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const originalImage = new Image();
    originalImage.src = images[selectedIndex].originalImage;

    originalImage.onload = () => {
      const scaleX = originalImage.naturalWidth / details.clientWidth;
      const scaleY = originalImage.naturalHeight / details.clientHeight;

      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;

      // Draw the cropped area
      ctx.drawImage(
        originalImage,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      const croppedImage = canvas.toDataURL("image/jpeg");
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, { image: croppedImage });
      setIsCropMode(false);
    };
    originalImage.onerror = () => {
      console.error("Error loading image for cropping.");
    };
  };

  const resetImage = () => {
    if (selectedIndex !== null) {
      const originalState = {
        brightness: 100,
        grayscale: 0,
        sepia: 0,
        saturate: 100,
        contrast: 100,
        hueRotate: 0,
        rotate: 0,
        vertical: 1,
        horizontal: 1,
        // signData: {
        //   position: { x: 0, y: 0 },
        //   size: { width: 100, height: 100 },
        //   url: undefined,
        //   color: "#000000",
        // },
        signData: {
          isPermentSave: false,
          signatures: [],
        },
      };
      pushStateToUndoStack(images[selectedIndex]);
      applyState(selectedIndex, originalState);
    }
  };

  const toggleCropMode = () => {
    setIsCropMode(!isCropMode);
  };

  const openSignaturePad = () => {
    setShowSignaturePad(true);
  };

  const clearSignatureOverlay = () => {
    const newdata = images[selectedIndex].signData;
    applyState(selectedIndex, {
      signData: {
        ...newdata,
        signatures: [],
        isPermentSave: false,
      },
    });
  };

  const saveSignature = () => {
    if (sigCanvas.current) {
      const signatureUrl = sigCanvas.current.toDataURL();
      setShowSignaturePad(false);
      // Get the current signature data
      const newData = images[selectedIndex].signData;

      // Create a new signature object
      const newSignature = {
        url: signatureUrl,
        id: uuidv4(),
        position: { x: 0, y: 0 },
        size: { width: 100, height: 100 },
        color: "#000000",
      };

      // Update the state with the new signature
      // applyState(selectedIndex, {
      //   signData: {
      //     ...newData,
      //     signatures: [...newData.signatures, newSignature], // Append new signature
      //   },
      // });

      // setImages((prevImages) =>
      //   prevImages.map((image) => ({
      //     ...image,
      //     signData: {
      //       ...image.signData,
      //       signatures: [
      //         ...image.signData.signatures,
      //         {
      //           url: signatureUrl,
      //           id: uuidv4(),
      //           position: { x: 0, y: 0 },
      //           size: { width: 100, height: 100 },
      //           color: "#000000",
      //         },
      //       ],
      //     },
      //   }))
      // );

      setImages((prevImages) =>
        prevImages.map((image) => {
          const hasSignature = image.signData.signatures.some(
            (signature) => signature.url
          );
          if (!hasSignature) {
            return {
              ...image,
              signData: {
                ...image.signData,
                signatures: [...image.signData.signatures, newSignature],
              },
            };
          }
          return image;
        })
      );
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleMouseDown = (e, selectedSignId) => {
    if (e.button === 0) {
      setIsDragging(true);
      setSignatureId(selectedSignId);
    }
  };

  const deleteSignature = (signatureId) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      const image = updatedImages[selectedIndex];
      image.signData.signatures = image.signData.signatures.filter(
        (signature) => signature.id !== signatureId
      );
      return updatedImages;
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (!images[selectedIndex]?.signData?.isPermentSave) {
        if (isDragging && !isResizing) {
          // Update the position of the signature being dragged
          applyState(selectedIndex, {
            signData: {
              ...images[selectedIndex].signData,
              signatures: images[selectedIndex].signData.signatures.map(
                (signature) =>
                  signature.id === signatureId
                    ? {
                        ...signature,
                        position: {
                          x: signature.position.x + e.movementX,
                          y: signature.position.y + e.movementY,
                        },
                      }
                    : signature
              ),
            },
          });
        } else if (isResizing && signatureRef.current) {
          // Update the size of the signature being resized
          const rect = signatureRef.current.getBoundingClientRect();
          const newWidth = Math.max(20, rect.width + e.movementX);
          const newHeight = Math.max(20, rect.height + e.movementY);
          applyState(selectedIndex, {
            signData: {
              ...images[selectedIndex].signData,
              signatures: images[selectedIndex].signData.signatures.map(
                (signature) =>
                  signature.id === signatureId
                    ? {
                        ...signature,
                        size: { width: newWidth, height: newHeight },
                      }
                    : signature
              ),
            },
          });
        }
      }
    },
    [isDragging, isResizing, selectedIndex, images, applyState, signatureId]
  );

  const handleResizeMouseDown = () => {
    setIsResizing(true);
  };

  const handleSaveImage = async () => {
    // Capture the div content

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    for (let i = 0; i <= images.length - 1; i++) {
      const canvas = await html2canvas(divRef.current, {
        useCORS: true,
        logging: true,
        scale: 1,
      });

      setSelectedIndex(i);
      await delay(100);

      const imgData = canvas.toDataURL(`image/png`);
      const image = new Image();
      image.src = imgData;
      image.onload = () => {
        // Create a new canvas to apply filters
        const filteredCanvas = document.createElement("canvas");
        filteredCanvas.width = image.width;
        filteredCanvas.height = image.height;
        const ctx = filteredCanvas.getContext("2d");

        // Apply filters
        ctx.filter = `
          brightness(${images[selectedIndex].brightness}%)
          grayscale(${images[selectedIndex].grayscale}%)
          sepia(${images[selectedIndex].sepia}%)
          saturate(${images[selectedIndex].saturate}%)
          contrast(${images[selectedIndex].contrast}%)
          hue-rotate(${images[selectedIndex].hueRotate}deg)
        `;

        // Draw the captured image onto the new canvas
        ctx.drawImage(image, 0, 0);

        // Convert the filtered canvas to a data URL and download it
        const filteredImgData = filteredCanvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = filteredImgData;
        link.download = "screenshot.png";
        link.click();
      };
    }
  };

  const handleSaveOnPortal = () => {
    if (images.length > 0) {
      openModal();
    } else {
      alert("No images have been selected for saving.");
    }
  };

  //handle resize btn not show in pdf
  const handleMouseEnter = (hoverSignId) => {
    setIsHoveringResize(true);
    setHoveredSignatureId(hoverSignId);
  };
  const handleMouseLeave = () => {
    setIsHoveringResize(false);
    setHoveredSignatureId(null);
  };

  const resizeHandleStyle = {
    display: isHoveringResize ? "block" : "none",
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "10px",
    height: "10px",
    backgroundColor: "red",
    cursor: "se-resize",
  };

  const captureScreenshot = async (isSaveOnPortal = false) => {
    setLoadingState((prevState) => ({ ...prevState, pdfLoading: true }));

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const pdfDoc = await PDFDocument.create();

    for (let i = 0; i < images.length; i++) {
      // Set the index to the current image
      setSelectedIndex(i);

      // Delay to ensure the image is rendered
      await delay(1000);

      // Capture the div content
      const imgDataUrl = await toPng(divRef.current, {
        cacheBust: true,
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });

      const image = new Image();
      image.src = imgDataUrl;

      // Wait for image to load
      await new Promise((resolve) => {
        image.onload = resolve;
      });

      // Get image dimensions
      const imgWidth = image.width;
      const imgHeight = image.height;

      // Create a new canvas to apply filters
      const filteredCanvas = document.createElement("canvas");
      filteredCanvas.width = imgWidth;
      filteredCanvas.height = imgHeight;
      const ctx = filteredCanvas.getContext("2d");

      // Apply filters
      ctx.filter = `
        brightness(${images[i].brightness}%)
        grayscale(${images[i].grayscale}%)
        sepia(${images[i].sepia}%)
        saturate(${images[i].saturate}%)
        contrast(${images[i].contrast}%)
        hue-rotate(${images[i].hueRotate}deg)
      `;
      ctx.drawImage(image, 0, 0);

      const filteredImgDataUrl = filteredCanvas.toDataURL("image/png");

      const imgBytes = await fetch(filteredImgDataUrl).then((res) =>
        res.arrayBuffer()
      );
      const pdfImage = await pdfDoc.embedPng(imgBytes);

      // Add a new page with the image's dimensions
      const page = pdfDoc.addPage([imgWidth, imgHeight]);
      page.drawImage(pdfImage, {
        x: 0,
        y: 0,
        width: imgWidth,
        height: imgHeight,
      });
    }

    setLoadingState((prevState) => ({ ...prevState, pdfLoading: false }));

    // Save the PDF
    const pdfBytes = await pdfDoc.save();
    if (isSaveOnPortal) {
      return pdfBytes;
    } else {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "screenshot2.pdf";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const processImage = async (src) => {
    setloadingScan(true); // Set loading to true before starting the process

    try {
      const img = new Image();
      img.src = src;
      img.onload = async () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        try {
          const {
            data: { blocks },
          } = await Tesseract.recognize(img, "eng");
          let imgWithBorder;

          if (blocks.length > 0) {
            // Handle the first detected object
            const firstBox = blocks[0].bbox;
            imgWithBorder = drawBorderAndGetImage(firstBox);
          } else {
            // No objects detected, use the whole image
            imgWithBorder = canvas.toDataURL("image/png");
          }
          applyState(selectedIndex, { image: imgWithBorder });
          const newdata = images[selectedIndex].scanData;
          applyState(selectedIndex, {
            scanData: {
              ...newdata,
              scanCropReady: true,
            },
          });
        } catch (error) {
          console.error("Error during Tesseract recognition:", error);
        } finally {
          setloadingScan(false); // Ensure loading is set to false in the finally block
        }
      };
    } catch (error) {
      console.error("Error loading image:", error);
      setloadingScan(false); // Ensure loading is set to false in case of an error
    }
  };

  const drawBorderAndGetImage = (box) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const { x0, y0, x1, y1 } = box;
    setdetectedObject({ x0, y0, x1, y1 });

    // Draw red border around the detected box
    ctx.strokeStyle = "red";
    ctx.lineWidth = 3;
    ctx.strokeRect(x0, y0, x1 - x0, y1 - y0);

    // Convert the canvas to a data URL
    return canvas.toDataURL("image/png");
  };

  const handleSaveScanObject = () => {
    if (cropper) {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      applyState(selectedIndex, {
        image: cropper.getCroppedCanvas().toDataURL(),
      });
      const newdata = images[selectedIndex].scanData;
      applyState(selectedIndex, {
        scanData: {
          ...newdata,
          scanCropReady: false,
        },
      });
    }
  };

  useEffect(() => {
    if (cropper) {
      const { x0, y0, x1, y1 } = detectedObject;
      // Calculate width and height
      const width = x1 - x0;
      const height = y1 - y0;

      // Calculate aspect ratio
      const aspectRatio = width / height;

      // Set the crop box data
      cropper.setCropBoxData({
        left: x0,
        top: y0,
        width: width,
        height: height,
      });

      // Optionally set the aspect ratio
      cropper.setAspectRatio(aspectRatio);
    }
  }, [cropper, detectedObject]);

  // Handle clicks outside the signature pad to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        signaturePadRef.current &&
        !signaturePadRef.current.contains(event.target)
      ) {
        setShowSignaturePad(false);
      }
    };
    if (showSignaturePad) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSignaturePad]);

  //save all signatures position and all
  const handleSaveAllSignature = () => {
    const newData = images[selectedIndex].signData;
    applyState(selectedIndex, {
      signData: {
        ...newData,
        isPermentSave: true,
      },
    });
    toast.success("Signature save succesfully");
  };

  const handleCancelImage = (index) => {
    if (index !== null && index >= 0 && index < images.length) {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);

      setImages(updatedImages);

      if (selectedIndex !== null) {
        if (index === selectedIndex) {
          if (updatedImages.length === 0) {
            setSelectedIndex(null);
          } else if (selectedIndex >= updatedImages.length) {
            setSelectedIndex(updatedImages.length - 1);
          } else {
            setSelectedIndex(selectedIndex);
          }
        } else if (index < selectedIndex) {
          setSelectedIndex((prev) => prev - 1);
        }
      }
    }
  };

  return (
    <div
      className="image_editor"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="card">
        <div className="card_header">
          <img src="./Images/Logo2.png" alt="Logo2" />
          {/* <h2>DDSHARED Image Scan</h2> */}
        </div>
        <div className="card_body">
          <div className="sidebar">
            <span className="filter_label">Filter</span>
            <div className="filter_element filter_key">
              {filterElement.map((val, i) => (
                <button
                  key={i}
                  className={val?.name === property?.name ? "active" : ""}
                  onClick={() => setProperty(val)}
                >
                  {val?.name}
                </button>
              ))}
            </div>

            <div className="filter_slider">
              <input
                type="range"
                name={property.name}
                max={property.maxValue}
                value={
                  selectedIndex !== null && selectedIndex < images.length
                    ? images[selectedIndex][property.name]
                    : 100
                }
                onChange={inputHandle}
              />
            </div>
            <div className="rotate-main">
              <button onClick={rotateLeft}>
                <GrRotateLeft />
              </button>
              <button onClick={rotateRight}>
                <GrRotateRight />
              </button>
              <button onClick={flipVertical}>
                <CgMergeVertical />
              </button>
              <button onClick={flipHorizontal}>
                <CgMergeHorizontal />
              </button>
              <button onClick={undo}>
                <IoMdUndo />
              </button>
              <button onClick={redo}>
                <IoMdRedo />
              </button>
            </div>
            <div className="reset">
              <button onClick={resetImage} className="reset-btn">
                Reset
              </button>
              {/* <button onClick={handleSaveImage} className="save-btn">
                Save Image
              </button> */}
              <button
                style={{
                  color: "white",
                  backgroundColor: "#f48127",
                  position: "relative",
                  cursor: loadingState.pdfLoading ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  if (images.length > 0) {
                    captureScreenshot(false);
                  } else {
                    alert("Select images");
                  }
                }}
                disabled={loadingState.pdfLoading}
              >
                {loadingState.pdfLoading ? (
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "14px",
                      color: "#fff",
                    }}
                  >
                    Loading...
                  </span>
                ) : (
                  "Download PDF"
                )}
              </button>
              <button onClick={handleSaveOnPortal} className="save-btn">
                save on DDShared portal
              </button>
            </div>
          </div>
          <div className="main_content">
            {!images.length && (
              <div className="side_element">
                <label htmlFor="upload">
                  <IoIosImage />
                </label>
                <div className="choose-file">
                  <input
                    type="file"
                    accept="image/*"
                    id="upload"
                    multiple
                    onChange={handleImageUpload}
                    ref={fileInputRef}
                  />
                  <button>Choose File</button>
                </div>
              </div>
            )}
            {images.length > 0 && (
              <div className="image_selector">
                {images.map((img, index) => (
                  <button
                    key={index}
                    className={selectedIndex === index ? "selected" : ""}
                    onClick={() => {
                      setSelectedIndex(index);
                      setDetails(null);
                    }}
                  >
                    Image {index + 1}
                    <span className="close-btn">
                      <IoMdCloseCircle
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the parent button click
                          handleCancelImage(index);
                        }}
                      />
                    </span>
                  </button>
                ))}

                <div className="choose-file">
                  <input
                    type="file"
                    accept="image/*"
                    id="upload"
                    multiple
                    onChange={handleImageUpload}
                    ref={fileInputRef}
                    style={{ display: "none" }} // Hide the input
                  />
                  <button onClick={() => fileInputRef.current.click()}>
                    Add
                  </button>
                </div>
              </div>
            )}
            {selectedIndex !== null && images.length > 0 && (
              <>
                {isCropMode ? (
                  <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                    <img
                      src={images[selectedIndex].originalImage}
                      alt="uploaded"
                      ref={(el) => setDetails(el)}
                    />
                  </ReactCrop>
                ) : images[selectedIndex]?.scanData.scanCropReady ? (
                  <Cropper
                    className="image_wrapper"
                    src={images[selectedIndex].originalImage}
                    initialAspectRatio={1}
                    viewMode={1}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    background={true}
                    zoomable={true}
                    rotatable={false}
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                  />
                ) : (
                  <div className="image_wrapper" ref={divRef}>
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                    <img
                      src={images[selectedIndex]?.image}
                      alt="uploaded"
                      className="uploaded-img"
                      style={{
                        filter: `
                          brightness(${images[selectedIndex]?.brightness}%)
                          grayscale(${images[selectedIndex]?.grayscale}%)
                          sepia(${images[selectedIndex]?.sepia}%)
                          saturate(${images[selectedIndex]?.saturate}%)
                          contrast(${images[selectedIndex]?.contrast}%)
                          hue-rotate(${images[selectedIndex]?.hueRotate}deg)
                        `,
                        transform: `
                          rotate(${images[selectedIndex]?.rotate}deg)
                          scale(${images[selectedIndex]?.vertical}, ${images[selectedIndex]?.horizontal})
                        `,
                      }}
                      ref={(el) => setDetails(el)}
                    />
                    <canvas ref={tempCanvasRef} style={{ display: "none" }} />
                    {images[selectedIndex]?.signData.signatures.map(
                      (signature) => (
                        <div
                          key={signature.id} // Unique key for each signature
                          className="signature_overlay"
                          onMouseEnter={() => handleMouseEnter(signature.id)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            top: signature.position.y,
                            left: signature.position.x,
                            position: "absolute", // Ensure the overlay is positioned correctly
                          }}
                          onMouseDown={(e) => handleMouseDown(e, signature.id)}
                          onClick={() => setSignatureId(signature.id)}
                        >
                          <img
                            src={signature.url}
                            alt="signature"
                            ref={signatureRef}
                            style={{
                              width: `${signature.size.width}px`,
                              height: `${signature.size.height}px`,
                            }}
                          />
                          {hoveredSignatureId === signature.id && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                cursor: "pointer",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                padding: "2px 4px",
                                borderRadius: "50%",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteSignature(signature.id);
                              }}
                            >
                              ✖
                            </div>
                          )}
                          {hoveredSignatureId === signature.id &&
                            !images[selectedIndex]?.signData.isPermentSave && (
                              <div
                                style={resizeHandleStyle}
                                onMouseDown={(e) =>
                                  handleResizeMouseDown(e, signature.id)
                                }
                              />
                            )}
                        </div>
                      )
                    )}
                  </div>
                )}
                <div className="crop_wrapper">
                  {/* {images[selectedIndex].image && (
                    <>
                      <button onClick={toggleCropMode}>
                        {isCropMode ? "Cancel Crop" : "Crop Image"}
                      </button>
                      {isCropMode && (
                        <button onClick={handleCrop}>Save Crop</button>
                      )}
                    </>
                  )} */}
                  <button onClick={openSignaturePad}>Add Signature</button>

                  {images[selectedIndex]?.scanData.scanCropReady ? (
                    <button
                      style={{
                        cursor: loadingScan ? "not-allowed" : "pointer",
                      }}
                      onClick={() => handleSaveScanObject()}
                    >
                      {loadingScan ? "loading..." : "Save Scan & Crop Image"}
                    </button>
                  ) : (
                    <button
                      onClick={() => processImage(images[selectedIndex]?.image)}
                    >
                      Scan & Crop Object
                    </button>
                  )}
                  {images[selectedIndex]?.signData.signatures.length > 0 && (
                    <button onClick={clearSignatureOverlay}>
                      Clear All Signature
                    </button>
                  )}
                  {!images[selectedIndex]?.signData.isPermentSave &&
                    images[selectedIndex]?.signData.signatures.length > 0 && (
                      <button onClick={handleSaveAllSignature}>
                        Save All Signature
                      </button>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showSignaturePad && (
        <div className="signature_pad_overlay">
          <div className="signature_pad" ref={signaturePadRef}>
            <SignatureCanvas
              ref={sigCanvas}
              penColor={images[selectedIndex]?.signData.color}
              canvasProps={{
                width: 300,
                height: 200,
                className: "signature_canvas",
              }}
            />
            <div className="signature_pad_controls">
              <button onClick={clearSignature} className="btn-clear">
                Clear
              </button>
              <button onClick={saveSignature} className="btn-save">
                Save
              </button>
            </div>
            <div className="color_picker">
              <SketchPicker
                color={images[selectedIndex]?.signData.color}
                onChangeComplete={(color) => {
                  const newColor = color.hex;
                  applyState(selectedIndex, {
                    signData: {
                      ...images[selectedIndex]?.signData,
                      color: newColor,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <FolderModel
          isOpen={isModalOpen}
          onClose={closeModal}
          generatePDFBytes={captureScreenshot}
        />
      )}
    </div>
  );
};

export default Main;
