import React, { useRef, useState, useEffect } from "react";

const DynamicWidthInput = () => {
  const [inputValue, setInputValue] = useState("");
  const spanRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
    }
  }, [inputValue]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <span style={{ position: "relative", display: "inline-block" }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        style={{
          border: "0",
          borderBottom: "1px solid #212121",
          outline: "none",
          fontSize: "16px",
          width: "fit-content",
          minWidth: "60px", // Set a minimum width for the input
        }}
        ref={inputRef}
      />
      <span
        ref={spanRef}
        style={{
          position: "absolute",
          visibility: "hidden",
          whiteSpace: "pre",
          fontSize: "16px",
          fontFamily: "inherit",
        }}
      >
        {inputValue || " "}
      </span>
    </span>
  );
};

export default DynamicWidthInput;
