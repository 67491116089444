import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Main from "./component/Main";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GeneratePDF from "./pages/GeneratePDF/GeneratePDF";
import Login from "./pages/Login/Login";
import PublicRoute from "./protectedRoute/PublicRoute";
import PrivateRoute from "./protectedRoute/PrivateRoute";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={<PrivateRoute element={<Main />} />}
          />
          {/* <Route
            path="/generatePDF"
            element={<PrivateRoute element={<GeneratePDF />} />}
          /> */}
          <Route
            path="/generatePDF"
            element={<GeneratePDF />}
          />
          <Route
            path="/login"
            element={<PublicRoute element={<Login />} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
