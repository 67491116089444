import { Navigate } from "react-router-dom";
import { getToken, getUserEmail } from "../utilites/getToken";

const PrivateRoute = ({ element }) => {
  const token = getToken();
  const email = getUserEmail();

  return token && email ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
