import React, { useState } from "react";
import { login } from "../../utilites/api";
import { useNavigate } from "react-router-dom";
import "../../component/style/login.scss";

function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validateData = () => {
    let isValid = true;
    let errors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data.email) {
      errors.email = "Email is required!";
      isValid = false;
    } else if (!emailRegex.test(data.email)) {
      errors.email = "Please enter a valid email address!";
      isValid = false;
    }

    if (data.password === "") {
      errors.password = "Password is required!";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateData()) {
      const response = await login(data.email, data.password);
      if (response) {
        navigate(`/generatePDF?email=${data.email}`);
      }
    }
  };

  return (
    <div className="login-main">
      <div className="login-box">
        <h2 className="login-head">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-box">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={data.email}
              onChange={handleChange}
              placeholder="Enter Email"
            />
            <span>{error.email}</span>
          </div>
          <div className="input-box">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={data.password}
              onChange={handleChange}
              placeholder="Enter Password"
            />
            <span>{error.password}</span>
          </div>
          <div className="submit-btn">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
