import axios from "axios"
import { toast } from 'react-toastify';
import { getToken } from "./getToken";

const baseUrl = process.env.REACT_APP_BACKEND_BASEURL

export const getFolderData = async (userToken) => {
    try {
        const response = await axios.get(`${baseUrl}/files/all`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
        return response.data
    } catch (error) {
        toast.error(error.response.data.message)
        return error
    }
}

export const uploadPDdfileToPortal = async (formData, userToken) => {
    try {
        const response = await axios.post(baseUrl + '/files/create_file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userToken}`,
            },
        });
        if (response.data.Files) {
            toast.success(response.data.message)
        }
        return response.data
    } catch (error) {
        toast.error(error.response.data.message)
        console.log('Error uploading PDF:', error);
    }
}

export const login = async (email, password) => {
    try {
        const response = await axios.post(baseUrl + "/user/login", {
            email: email,
            password: password
        })
        if (response.data) {
            toast.success(response.data.message)
        }
        return response.data
    } catch (error) {
        toast.error(error.response.data.message)
        console.log('Error login user', error);
    }
}

export const sendEmail = async (formData) => {
    try {
        const response = await axios.post(baseUrl + "/files/sendfile-email", formData)
        if (response.data) {
            toast.success(response.data.message)
        }
        return response.data
    } catch (error) {
        toast.error(error.response.data.message)
        console.error('Error sending email', error);
    }
}